.marketing {
  /* Standard elements. */
  main {
    margin: 0 1rem;
  }

  /* The Hero section. */
  .hero {
    background-image: url("/img/AdobeStock_215964640_2000x974.jpg");
    background-position: center center;
    background-size: cover;
    min-height: 30rem;

    h1 {
      font-size: 2rem;
      text-align: center;
    }

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      max-width: 14rem;
      margin-left: 1rem;
    }

    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-left: 1rem;

      a {
        display: inline-block;
        background: #15448e;
        color: #fff;
        border-radius: 0.5rem;
        padding: 0.75rem 1rem;
        text-decoration: none;

        &:hover {
          background: #70b8ef;
          color: #000;
        }
      }
    }
  }

  /* Media query adjustments */
  @media screen and (min-width: $breakpoint-xsmall) {
    .hero {
      h1 {
        font-size: 2rem;
        margin: 3rem 0 0 1rem;
        text-align: left;
      }

      h2 {
        max-width: 18rem;
      }

      h3 {
        max-width: 18rem;
      }
    }
  }

  /* Media query adjustments */
  @media screen and (min-width: $breakpoint-small) {
    .hero {
        h1 {
        margin: 8rem 0 0 1rem;
      }

      h2 {
        max-width: 24rem;
      }

      h3 {
        max-width: 24rem;
      }
    }
  }

  /* Media query adjustments */
  @media screen and (min-width: $breakpoint-medium) {
    .hero {
      h1 {
        margin-left: 4rem;
      }

      h2 {
        margin-left: 4rem;
        max-width: inherit;
      }

      h3 {
        margin-left: 4rem;
        max-width: inherit;
      }
    }
  }

  /* The three callouts. */
  .three-callouts {
    display: flex;
    flex-direction: column;
  }

  /* Media query adjustments */
  @media screen and (min-width: $breakpoint-medium) {
    main {
      margin: 0 2rem;
    }

    .three-callouts {
      flex-direction: row;
      justify-content: space-between;

      div {
        width: 30%;
      }
    }
  }

  /* Video samples. */
  .video-samples {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    p {
      border: 0.16rem solid #ddd;
      width: 200px;
      height: 160px;
    }
  }
}
