@charset "UTF-8";

@import "variables";

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 20;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $background-color;
  box-shadow: 0.1rem 0.1rem 0.2rem $shadow-color;
  padding: 1rem 0.5rem;
  z-index: 19;
}

.dropdown-content li {
  white-space: nowrap;
}

.dropdown-right {
  right: 0;
}

.dropdown:hover,
.dropdown-is-visible {
  color: $accent-color;
}

.dropdown:hover > a,
.dropdown-is-visible > a {
  color: inherit;
}

.dropdown:hover .dropdown-content,
.dropdown-is-visible .dropdown-content {
  display: block;
}
