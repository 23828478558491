#sign-up-form {
  .form-pair {
    & > * {
      width: 9rem;
    }
  }

  .card-cvd {
    width: 3rem;
  }

  .card-expiry {
    & > * {
      width: 2.5rem;
    }
  }

  .accept-credit-card {
    font-weight: bold;
    margin: 0 0 0.6rem 0;
    padding: 0;
  }

  .field-error {
    color: #c33;
    font-style: italic;
    width: 100%;
  }
}
