@charset "UTF-8";

@import "variables";

.drawer.drawer-is-active {
  display: block;
}

.drawer.drawer-is-visible .drawer-wrapper {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.drawer.drawer-is-visible .drawer-overlay {
  opacity: 0.5;
}

a[data-drawer-trigger] {
  text-decoration: none;
}

a[data-drawer-trigger] svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: $primary-color;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  opacity: 0;
  transition: opacity 0.3s;
  will-change: opacity;
  background-color: $primary-color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.drawer-header {
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color;
}

.drawer-close {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin: 0;
}

.drawer-close svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: $secondary-color;
}

.drawer-close svg:hover {
  fill: $accent-color;
}

.drawer-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  max-width: 18rem;
  z-index: 9999;
  overflow: auto;
  transition: transform 0.3s;
  will-change: transform;
  background-color: $background-color;
  color: $primary-color;
  display: flex;
  flex-direction: column;
  -webkit-transform: translateX(103%);
  transform: translateX(103%); /* extra 3% because of box-shadow */
  -webkit-overflow-scrolling: touch; /* enables momentum scrolling in iOS overflow elements */
  box-shadow: 0 2px 6px $shadow-color;
}

.drawer-left .drawer-wrapper {
  left: 0;
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.drawer-title {
  padding: 0.5rem 0;
}

.drawer-title img {
  height: 1.8rem;
}

.drawer-content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
  padding: 0.5rem;
}

.drawer-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.drawer-content li {
  margin: 0;
  padding: 0;
}

.drawer-content li.drawer-divider {
  padding-top: 0.25rem;
  margin: 0 0.4rem 0.25rem 0.4rem;
  border-bottom: 1px solid $border-color;
}

.drawer-content a {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  text-decoration: none;
}
