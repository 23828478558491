.exploration {
  .categories {
    display: flex;
    flex-direction: column;


    margin-bottom: 1rem;
  }

  .playlist {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .video-card {
      img {
        height: 124px;
        width: 220px;
      }
    }
  }

  .playlists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    .thumbnail {
      height: 124px;
      width: 220px;
    }

    .name {
      width: 220px;
      flex: 1;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 0.86rem;
      font-weight: bold;
      text-align: left;
    }

    .duration {
      font-size: 0.8rem;
    }
  }

  .video-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    text-decoration: none;

    img {
      height: 80px;
      width: 142px
    }
  }

  /* Media query adjustments */
  @media screen and (min-width: $breakpoint-small) {
    .video-card {
      flex-direction: row;
    }
  }
}
