@charset "UTF-8";

@import "variables";

nav {
  box-shadow: 0 0 0.2rem $primary-color;
  padding: 0.75rem 0;
  margin-bottom: 0.2rem;
  font-size: 1.1rem;
}

nav a {
  display: inline-block;
  padding: 0.5rem;
  text-decoration: none;
}

nav .header-logo {
  padding: 0 0.5rem 0 0.5rem;
}

nav .header-logo img {
  height: 2rem;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
  padding: 0;
}

nav .nav-left {
  min-width: 2rem;
}

nav .nav-left {
  margin-left: 0.5rem;
}

nav .nav-right {
  margin-right: 0.5rem;
}

nav .nav-center {
  flex-grow: 1;
  margin: 0 1rem;

  align-items: center;
  justify-content: center;
}

nav .nav-center form {
  width: 100%;
  max-width: 50rem;

  display: flex;
  flex-direction: row;
}

nav .nav-center input[type=search] {
  flex-grow: 1;

  border-color: #ddd;
  border-right: none;
  border-radius: 0.5rem 0 0 0.5rem;
  font-size: 1.1rem;
  width: 1rem;
  padding: 0.5rem 1rem;
}

nav .nav-center button[type=submit] {
  background-color: $default-control-color;
  border-color: $default-control-color;
  color: $primary-color;
  border-radius: 0 0.5rem 0.5rem 0;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
}

nav .nav-center button[type=submit]:hover {
  background-color: $accent-color;
  border-color: $accent-color;
  color: $background-color;
}
