@charset "UTF-8";

@import "variables";

/* General */
.inline-block {
  display: inline-block;
}

/* Links */
a {
  color: $secondary-color;
  cursor: pointer;
}

a:hover {
  color: $accent-color;
}

a.button {
  text-decoration: none;
}

/* Forms */
form {
  display: flex;
  flex-direction: column;

  p {
    margin: 0.5rem 0;
    text-align: center;
  }
}

label {
  display: inline-block;
  background: transparent;
  border: 0.1rem solid transparent;
  font-size: 1rem;
  padding: 0.25rem 0 0.25rem 0;
}

a.button,
button,
button[type=submit] {
  display: inline-block;
  background: $secondary-color;
  border: 0.1rem solid $border-color;
  color: $background-color;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.25rem 1rem;

  &.default {
    background: $default-control-color;
  }
}

input[type=password],
input[type=search],
input[type=text] {
  border: 0.1rem solid $border-color;
  font-size: 1rem;
  outline: none;
}

.form-pair {
  & > * {
    width: 5rem;
  }

  & > * + * {
    width: calc(100% - 6rem);
  }
}

@import "components/core";
@import "components/drawer";
@import "components/dropdown";
@import "components/nagivation";

@import "page-types/centered-content";
@import "page-types/exploration";
@import "page-types/marketing";

@import "page-specifics/registration";
