main {
  margin: 0rem 1rem 1rem;
}

/* Tables */
table {
  border-collapse: collapse;
}

td,
th {
  text-align: left;
  vertical-align: top;
  padding: 0.25rem 0.5rem;
}

.table-list {
  border: 0.16rem solid #ddd;

  th {
    background: #ccc;
  }
}

.table-zebra tr:nth-child(even) {
  background: #e0e0e0;
}

.table-row-highlight {
  a {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  tr:hover {
    background: $default-control-color;
    cursor: pointer;
  }
}
