$breakpoint-xsmall: 321px !default;
$breakpoint-small: 641px !default;
$breakpoint-medium: 961px !default;
$breakpoint-large: 1201px !default;
$breakpoint-xlarge: 1601px !default;

$default-font-family: 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$background-color: #fff;
$primary-color: #444;
$border-color: #aaa;
$secondary-color: #7b8da4;
$default-control-color: #70b8ef;
$accent-color: #15448e;
$stripe-color: #f3f3f3;
$shadow-color: #777;
